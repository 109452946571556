<template>
  <div :style="slidePosition" class="z-6 w-100 h-100">
    <div class="position-absolute text-warning softMatterWrapper "
      style="width:37.5%;left:3.5%;top:37.5%;transform:translate(0%,-50%)">
      <img src="images/Slide20/soft-matter.png" class="softMatter" style="width:100%" />
    </div>
    <div iv class="position-absolute text-white " style="width:51.5%;left:41.5%;top:29%;transform: translateY(-50%)">
      <h3 class="font-vw-3-5 font-w-600 mb-0 fade20" style="opacity:0;">
        2003
      </h3>
      <h6 class="font-vw-2-5 font-w-600 fade20 " style="opacity:0; ">
        MATERIALS FROM INSIDE-OUT
      </h6>
    </div>
    <div iv class="position-absolute text-white " style="width:51.5%;left:41.5%;top:42%;transform: translateY(-0%)">
      <p class="font-vw-1-35 text-20" style="opacity:0; ">
        While materials science and μSR (muon spin
        rotation/relaxation/resonance) have been a part of TRIUMF’s research
        program since its early years, 2003 saw the μSR facility renamed as the
        Centre for Molecular and Materials Sciences—a name more inclusive of the
        various techniques μSR and β-NMR (beta-detected nuclear magnetic
        resonance) used to peer into materials and gain a deeper understanding
        of their qualities. Using the muon, the heavier cousin of the electron,
        as well as other rare isotopes, scientists can understand a material’s
        characteristics at the atomic level. In 2010, TRIUMF’s muon probes shed
        light on the mechanisms behind high-temperature superconductivity—a
        topic studied at TRIUMF from as early as 1991, which has the potential
        to revolutionize the use of superconductors.
      </p>
    </div>
  </div>
</template>

<script>
import anime from "animejs"
import { normalize } from "@/utils/ranges"
import { slidePositionPlain } from "@/utils/slidePositioning"
export default {
  props: ["globalUnits", "slideIndex"],
  computed: {
    slidePosition() {
      //console.log("SLIDES INDEX", this.slideIndex)
      return slidePositionPlain(this.globalUnits, this.slideIndex)
    },
    innerPosition() {
      return (
        (this.globalUnits - this.slideIndex.start) /
        (this.slideIndex.end - this.slideIndex.start)
      )
    }
  },
  mounted() {
    //this.startLoop()
  },
  methods: {
    slide20(percentage) {
      let animation = anime.timeline({
        autoplay: false,
        loop: false,
        endDelay: 500
      })

      animation
        .add({
          targets: ".fade20",

          translateY: ["-50%", "0%"],
          opacity: [0, 1],
          duration: 100,
          easing: "easeInOutSine",
          delay: (el, i) => 5000 + 30 * i
        })
        .add({
          targets: ".text-20",

          opacity: [0, 1],

          translateY: ["35%", "0%"],
          duration: 100,
          easing: "easeInOutSine"
        })
      var progress =
        percentage <= 0.5 ? percentage * 2 : normalize(percentage, 0.5, 1, 1, 0)
      animation.seek(animation.duration * progress)
    },
    softMatter(percentage) {
      let animation = anime
        .timeline({
          autoplay: false,
          loop: false,
          endDelay: 500
        })
        .add({
          targets: ".softMatterWrapper",
          opacity: [0, 1],
          easing: "easeInOutSine",
          duration: 500,
          delay: 1500
        })
      var progress =
        percentage <= 0.5 ? percentage * 2 : normalize(percentage, 0.5, 1, 1, 0)
      animation.seek(animation.duration * progress)
    },
    startLoop() {
      anime({
        targets: ".particle",
        width: ["32%", "35%"],
        direction: "alternate",
        loop: true,
        duration: 1200,
        easing: "easeInOutSine"
      })
    }
  },
  watch: {
    innerPosition() {
      this.softMatter(this.innerPosition)

      this.slide20(this.innerPosition)
    }
  }
}
</script>

<style></style>
